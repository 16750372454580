import { pcWake, sendCommand, sendNotification, setTvState, systemRestart } from '../tools/backend';
import { Button } from '../components/Button';
import { ButtonContainer } from '../components/ButtonContainer';


export function Home() {
  return (
    <div id='home'>
      <ButtonContainer>
        <Button text='Restart fast' onClick={() => systemRestart('fast')} />
        <Button text='Restart backend' onClick={() => systemRestart('back')} />
        <Button text='Restart frontend' onClick={() => systemRestart('front')} />
        <Button text='Restart full' onClick={() => systemRestart('full')} />
      </ButtonContainer>

      <ButtonContainer>
        <Button text='PC wake' onClick={() => pcWake()} />
        <Button text='PC sleep' onClick={() => sendCommand('sleep')} />
        <Button text='PC off' onClick={() => sendCommand('shutdown')} />
      </ButtonContainer>

      <ButtonContainer>
        <Button text='Greetings' onClick={() => sendNotification('Hello there!')} />
        <Button text='Idle' onClick={() => sendCommand('idle')} />
        <Button text='Speakers' onClick={() => sendCommand('toggle_speakers')} />
      </ButtonContainer>

      <ButtonContainer>
        {/* <Button text='Bed on' onClick={() => iftttEvent('light1_high')} /> */}
        <Button text='SD' onClick={() => sendCommand('run_sd')} />
        <Button text='TV on' onClick={() => setTvState(true)} />
      </ButtonContainer>

      <ButtonContainer>
        {/* <Button text='Bed off' onClick={() => iftttEvent('lights_off')} /> */}
        <Button text='Image browser' onClick={() => sendCommand('run_sdd')} />
        <Button text='TV off' onClick={() => setTvState(false)} />
      </ButtonContainer>
    </div>
  );
}